<template>
    <v-container fluid v-if="team">
        <router-view></router-view>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'team-layout',
    data: () => ({}),
    created() {
        this.fetchTeam();
    },
    computed: {
        ...mapGetters(['team']),
    },
    methods: {
        ...mapActions(['findTeam']),
        fetchTeam() {
            const id = this.team ? this.team.id : undefined;
            const idToGet = this.$route.params.team_id;

            if (idToGet !== id) {
                this.findTeam(idToGet);
            }
        },
    },
};
</script>

<style></style>
